<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            :pk="publishableKey"
            :line-items="lineItems"
            :success-url="successURL"
            :cancel-url="cancelURL"
            @loading="(v) => (loading = v)"
          />
          <v-card class="mx-auto" max-width="344" outlined>
            <div class="text-center pa-1 text-h6">RM 10.00</div>
            <v-card-actions>
              <v-btn color="primary" @click="submit" :loading="isLoading">Pay now!</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    event: (state) => state.event.data,
    boss: (state) => state.boss.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    isLoading:false,
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    loading: false,
    lineItems: [
      {
        price: process.env.VUE_APP_STRIPE_PRICE_ID, // The id of the one-time price you created in your Stripe dashboard
        quantity: 1,
      },
    ],
    successURL:null,
    cancelURL: null,
  }),
  created() {
    //
  },
  mounted() {
    this.successURL =`${process.env.VUE_APP_URL}/${this.$_getLocale()}/landing`
    this.cancelURL =`${process.env.VUE_APP_URL}/${this.$_getLocale()}/landing`
  },
  methods: {
    submit() {
      this.isLoading = true
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>